import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeIndex from '../views/HomeIndex.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeIndex,
    meta:{'title':'首页'}
  },
{
    path:'/product',
    name:'product',
    component:()=> import('../views/product/Product.vue'),
    meta:{'title':"产品服务",'activeMenu':'/product'}
  }
  ,{
    path:'/evs',
    name:'evs',
    component:()=>import('../views/product/Evs.vue'),//EVS智慧云平台
    meta:{'name':'/evs','activeMenu':'/product','title':"EVS智慧云平台"}
  },{
    path:'/yapin',
    name:'yapin',
    component:()=>import('../views/product/Yapin.vue'),
    meta:{'name':'/yapin','activeMenu':'/product'}
  },{
    path:'/bank',
    name:'bank',
    component:()=>import('../views/product/Bank.vue'),//数字银行
    meta:{'name':'/bank','activeMenu':'/product'}
  },{
    path:'/datascreen',
    name:'datascreen',
    component:()=>import('../views/product/DataScreen.vue'),//不动产数据大屏
    meta:{'name':'/datascreen','activeMenu':'/product'}
  },{
    path:'/enquiry',
    name:'enquiry',
    component:()=>import('../views/product/EnquiryAssistant.vue'),//不动产询价助手
    meta:{'name':'/enquiry','activeMenu':'/product'}
  },{
    path:'/clf',
    name:'clf',
    component:()=>import('../views/product/Clf.vue'),//存量房
    meta:{'name':'/clf','activeMenu':'/product'}
  },{
    path: '/valuation',
    name: 'valuation',
    component: () => import('../views/Valuation.vue'),
    meta:{'title':"服务与支持"}
  },
  {
    path: '/solution',
    name: 'solution',
    component: () => import('../views/Solution.vue'),
    meta:{'title':"解决方案",'activeMenu':'/solution'}
  },
  {
    path: '/digital',
    name: 'digital',
    component: () => import('../views/solution/Digital.vue'),
    meta:{'title':"数字经验解决方案",'activeMenu':'/solution'}
  },
  {
    path: '/asset',
    name: 'asset',
    component: () => import('../views/solution/Asset.vue'),
    meta:{'title':"资产运营解决方案",'activeMenu':'/solution'}
  },
  {
    path: '/wisdom',
    name: 'wisdom',
    component: () => import('../views/solution/Wisdom.vue'),
    meta:{'title':"奇谋智库",'activeMenu':'/solution'}
  },
  {
    path: '/adviser',
    name: 'adviser',
    component: () => import('../views/solution/Adviser.vue'),
    meta:{'title':"数字参谋",'activeMenu':'/solution'}
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/About.vue'),
    meta:{'title':"了解奇谋"}
  }
]

const router = new VueRouter({
  routes,
  mode:'history'
})

export default router
