const state = {
    company:'',
    menuId:''
  }
  
  const mutations = {
    companyInfo:(state,info)=>{
        state.company = info
    },
    setMenuId:(state,info)=>{
        state.menuId = info
    }
  }
  
  const actions = {
    getCompanyInfo(context,payload){
        return new Promise((resolve,reject)=>{
            context.commit('companyInfo',payload)
        })
    },
    getMenuId(context,payload){
       return new Promise((resolve,reject)=>{
          context.commit('setMenuId',payload)
       })
    }
  }
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions
  }