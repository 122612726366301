<template>
    <div class="left-float-box">
        <ul>
            <li class="text-center" @mouseenter="floatType(1)" @mouseleave="moveType(1)">
                <span class="iconfont icon-icon-dianhua1"></span>
                <div class="phone-box animate__fadeInRight animate__animated" :class="{'cur':floatType1}" v-if="floatType1">
                    {{companyInfo.companyPhone}}
                </div>
            </li>
            <li class="text-center" @mouseenter="floatType(2)" @mouseleave="moveType(2)">
                <span class="iconfont icon-icon-youxiang1"></span>
                <div class="email-box animate__fadeInRight animate__animated" v-if="floatType2" :class="{'cur':floatType2}">
                    {{companyInfo.companyEmail}}
                </div>
            </li>
            <li class="text-center" @mouseenter="floatType(3)" @mouseleave="moveType(3)">
                <span class="iconfont icon-icon-erweima1"></span>
                <div class="ewm-box animate__fadeInRight animate__animated" v-if="floatType3" :class="{'cur':floatType3}">
                    <img :src="companyInfo.qrCode" alt="">
                </div>
            </li>
            <li class="text-center" @mouseenter="floatType(4)" @mouseleave="moveType(4)">
                <span class="iconfont icon-icon-xinxi1"></span>
                <div class="leaving-msg-box animate__fadeInRight animate__animated"  @mouseenter="guestBook" v-if="floatType4||guestType" :class="{'cur':floatType4||guestType}">
                    <div class="leaving-img-in-box">
                         <div class="leaving-title flex">
                        <div class="title-span"><span class="title">留言板</span></div>
                        <div class="iconfont-span title-span" @click="closeLeaving()"><span class="iconfont icon-icon-shouqi"></span></div> 
                    </div>
                    <div class="leaving-form">
                        <el-form :model="ruleForm"  ref="ruleForm" :rules="rules" class="demo-ruleForm">
                            <el-form-item label="" prop="guestbookName">
                                <el-input v-model="ruleForm.guestbookName" placeholder="请输入姓名"></el-input>
                            </el-form-item>
                            <el-form-item label="" prop="guestbookPhonenumber">
                                <el-input v-model="ruleForm.guestbookPhonenumber" placeholder="请输入手机号码"></el-input>
                            </el-form-item>
                             <el-form-item label="" prop="guestbookCompany">
                                <el-input v-model="ruleForm.guestbookCompany" placeholder="请输入机构名称"></el-input>
                            </el-form-item>
                             <el-form-item label="" prop="guestbookDetails">
                                <el-input type="textarea" v-model="ruleForm.guestbookDetails" placeholder="请输入咨询内容"></el-input>
                            </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="submitForm(ruleForm)">提交</el-button>
                        </el-form-item>
                        </el-form>
                    </div>

                    </div>
                   
                </div>
            </li>
            <li class="text-center" @click="returnTop"><span class="iconfont icon-icon-huidingbu1"></span></li>
        </ul>
    </div>
</template>
<script>
import { addQmGwBookingInfo,getQmGwCompany } from '@/api/api'
export default {
    name:'LeftFloat',
    props:{
        companyInfo:{
            type:[Object,String],
            default:''
        }
    },
    data(){
        const validateMobile = (rule, value, callback) => {
            const reg = /^1[3-9]\d{9}$/
            if (value === '') {
                callback(new Error('请输入手机号码'))
            } 
            else if (!reg.test(value)) {
            callback(new Error('请输入正确的11位手机号'))
            } 
            else {
                callback()
            }
        }
        return{
            ruleForm: {
            guestbookName: '',
            guestbookPhonenumber: '',
            guestbookCompany: '',
            guestbookDetails: '',
            },
            rules: {
                guestbookName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
                guestbookPhonenumber:[{required:true,validator: validateMobile,trigger:'blur'}]
            },
            floatType1:false,
            floatType2:false,
            floatType3:false,
            floatType4:false,
            guestType: false,
        }
    },
    created(){
        // this.initData()
    },
    methods:{
        returnTop(){
        let timer = setInterval(function(){
            let osTop = document.documentElement.scrollTop || document.body.scrollTop;
            let speed = Math.floor(-osTop / 6);
            document.documentElement.scrollTop = document.body.scrollTop = osTop + speed;
            if(osTop == 0){
                clearInterval(timer);
            }
        },80);
        },
        // initData(){
        //     getQmGwCompany().then(res=>{
        //         if(res.code == 0){
        //              this.$store.dispatch('gw/getCompanyInfo',res.data)
        //              this.companyInfo = res.data
                    

        //         }
                
        //     })
        // },
        // 提交
        submitForm(ruleForm){
            this.$refs['ruleForm'].validate((valid) => {
                if (valid) {
                   addQmGwBookingInfo({...this.ruleForm}).then(res=>{
                        this.$message({
                        message: '提交成功',
                        type: 'success'
                        });
                        this.resetForm()
                    })
                } else {
                    this.$message({
                    message: '请填写必填项后再提交',
                    type: 'error'
                    });
                }
                });
        },
        floatType(type){
            switch (type) {
                case 1:
                    this.floatType1 = true
                    this.guestType = false
                    this.resetForm()
                    break;
                case 2:
                    this.floatType2 = true
                     this.guestType = false
                     this.resetForm()
                    break;
                case 3:
                    this.floatType3 = true
                    this.guestType = false
                    this.resetForm()
                    break;
                case 4:
                    this.floatType4 = true
                    break;
                default:
                    break;
            }
        },
        moveType(type){
             switch (type) {
                case 1:
                    this.floatType1 = false
                    break;
                case 2:
                    this.floatType2 = false
                    break;
                case 3:
                    this.floatType3 = false
                    
                    break;
                case 4:
                    this.floatType4 = false
                    break;
                default:
                    break;
            }
        },
        closeLeaving(){
            this.floatType4 = false
            this.guestType = false
            this.resetForm()
        },
        guestBook(){
            this.guestType = true
        },
        resetForm(){
            this.ruleForm.guestbookName = '';
            this.ruleForm.guestbookPhonenumber ='';
            this.ruleForm.guestbookCompany ='';
            this.ruleForm.guestbookDetails ='';
        }
    
    }
}
</script>

<style scoped lang='scss'>
.left-float-box{
    position:fixed;
    right:0;
    z-index: 100;
    width:50px;
    height:250px;
    top:50%;
    margin-top:-125px;
    background:#fff;
    border-radius:4px 0px  0px 4px;
    box-shadow: 0 0 3px #c9c9c9;
    li{
        height: 50px;
        width:50px;
        align-items: center;
        justify-content: center;
        text-align: center;
        display: flex;
        position:relative;
        z-index: 33;
        transition: all 0.1s;
        cursor: pointer;
        span.iconfont{
            font-size:30px;
            color:#c9c9c9;
            display: inline-block;
            height:30px;
        }
        .phone-box,.email-box,.ewm-box,.leaving-msg-box{
            width: 200px;
            height:50px;
            line-height: 50px;
            text-align: center;
            font-size: 24px;
            color: #ffffff;
            font-weight: bold;
            background: #0069EA;
            position: absolute;
            left: 0px;
            top: 0;
            z-index: -11;
            border-radius: 4px;
            opacity: 0;
            transition: all 0.5s;
        }
        .phone-box:after,.email-box:after,.leaving-msg-box:after,.ewm-box:after{
            content: "";
            position: absolute;
            bottom: 17px;
            right: -15px;
            border-width:8px;
            border-style: solid;
            border-color: transparent transparent transparent #0069EA;
        }
        .leaving-msg-box:after{
            border-color: transparent transparent transparent #fff;
            bottom: 162px;
            right:-5px;
        }
        .ewm-box:after{
            bottom:60px;
            right:-16px;
             border-color: transparent transparent transparent #fff;
        }
        .email-box{
            width:320px;
            
        }
        .ewm-box{
            width:100px;
            height:100px;
            box-shadow: 0 0 5px #c9c9c9;
            img{
                width:100%;
                height:100%;
            }
        }
        .leaving-msg-box{
            width:360px;
            height:396px;
            line-height: unset;
            // opacity: 1;
            // left:-370px;
            top:-200px;
            // background:#fff;
            background:transparent;
            .leaving-title{
                margin-bottom:15px;
            }
            .title{
                font-size:16px;
                color:#1a1a1a;
                
            }
            .title-span{
                flex:1;
                -webkit-flex:1;
                -moz-flex:1;
                text-align: left;
            }
            .iconfont-span{
                text-align: right;
                span{
                    color:#c9c9c9;
                }
            }
            .el-button{
                width:100%;
                background: #0069EA linear-gradient(to right, #0069EA, #188FFE);
            }
        }
        .leaving-img-in-box{
            background:#fff;
            box-shadow: 0 0 3px #c9c9c9;
            padding:15px;
            width:350px;
        }
    }        
    .phone-box.cur{  
            left:-210px;
            opacity: 1;
        }
    .email-box.cur{  
        left:-330px;
        opacity: 1;
    }
    .ewm-box.cur{
        left:-110px;
        opacity: 1;
    }
    .leaving-msg-box.cur{
        opacity: 1;
        left:-360px;
    }
    li:hover{
         background:#0069EA;
         span{
            color:#fff;
        }
    }
}
</style>