<template>
    <div class="index-page">
        <NavMenu  @getDataList = 'getDataList'></NavMenu>
        <div class="silder-box">
            <el-carousel  :autoplay="false" trigger="click" height="780px">
                <el-carousel-item v-for="(item,index) in BannerList.qmGwColumnList" :key="index">
                  <img :src="item.columnUrl" alt="">
                  <div class="public-page banner-index">
                    <div class="banner-box">
                      <h4 class="h4Color">{{item.columnTitle}}</h4>
                      <p class="adv-des-txt" v-html="item.columnDetails"></p>
                      <el-button size="small" type="primary" v-if="item.columnDescribe">{{item.columnDescribe}}<i class="el-icon-right el-icon--right"></i></el-button>
                     </div>
                  </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <!-- 公司实力版图  -->
        <div class="company-adv-box">
          <Title :title="advInfor.menuName" :type='true'></Title>
            <div class="public-page"> 
                <ul class="adv-item clearfix">
                    <li class="fl" v-for="(item,index) in advInfor.qmGwColumnList" :key="index">
                      <div class="adv-box">
                        <div class="top-adv text-center">
                            <div class="adv-img">
                              <img :src="item.columnUrl" alt="">
                            </div>
                            <h4 class="h4Color">{{item.columnTitle}}</h4>
                            <p class="adv-small-title">{{item.columnDescribe}}</p>
                        </div>
                      </div>
                      <div class="adv-des">
                         <h4 class="h4Color">{{item.columnTitle}}</h4>
                         <p class="adv-des-txt" v-html="item.columnDetails"></p>
                      </div>
                    </li>
                    <li class="fl">
                      <div class="adv-box">
                        <div class="top-adv text-center">
                            <div class="adv-img">
                              <img src="@/assets/images/adv-other.png" alt="">
                            </div>
                            <h4></h4>
                            <p class="adv-small-title">...</p>
                        </div>
                      </div>
                       
                    </li>
                </ul>
            </div>
        </div>
        <!-- 核心优势 -->
         <div class="product">
           <Title :title="corAdvInfo.menuName" :type='true'></Title>
            <div class="product-center public-page">
                 
                <div class="product-box clearfix">
                    <div class="item" :class="prodCur==index?'on':''" v-for="(item,index) in corAdvInfo.qmGwColumnList" :key="index" @mouseenter="prod(index)">
                        <div class="off">
                            <p class="txt1">{{item.columnTitle}}</p>
                            <p class="txt2">{{item.columnDescribe}}</p>
                            <img :src="item.columnUrl" alt="">
                        </div>
                        <div class="open">
                            <p class="txt1">{{item.columnTitle}}</p>
                            <p class="txt2" v-html="item.columnDetails"></p>
                            <img :src="item.columnUrl" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 解决方案 -->
        <div class="productSolution">
              <div class="contain public-page">
                  <div class="prod-nav">
                      <div class="prod-nav-header">
                          <h2>{{solutionList.menuName}}</h2>
                      </div>
                      <ul>
                         <li :class="{'active':solutionIndex==index}" @mouseenter="solution(index)" v-for="(item,index) in solutionList.qmGwColumnList" :key="index">
                          <div class="solution-title-box">
                            <div class="solution-icon"><img src="@/assets/images/icon-title.png" alt="" class="icon-img"> <img src="@/assets/images/icon-title-hover.png" alt="" class="icon-img-hover"></div>
                            <div class="solution-title">
                                <h2 class="h4Color font24">{{item.columnTitle}}</h2>
                                <p class="soltion-small color">{{item.columnDescribe}}</p>
                            </div>
                          </div>
                        </li>
                     
                      </ul>
                  </div>
                  <div class="prod-list">  
                      <div class="prod-cell">
                            <div v-for="(item,index) in solutionList.qmGwColumnList" :key="index">
                              <div class="prod-item animate__animated animate__fadeInUp" v-if="solutionIndex == index" >
                                 <div class="soltion-list-div" v-html="item.columnDetails"></div>    
                              </div>
                            </div>
                      </div>
                  </div>
              </div>
        </div>
        <!-- 产品服务 -->
      <div class="product-serve-wrapper">
          <Title :title="prodServeInfo.menuName" :type='true'></Title>
          <div class="public-page">
              <ProductServe :prodServeInfo = 'prodServeInfo'></ProductServe>
          </div>
      </div>
      <!-- 合作伙伴 -->
      <div class="choose">
          <Title :title="cooperInfo.menuName" :type='true'></Title>
      
          <!-- <div class="choose-wrapper anna-choose" v-for="(item,index) in cooperInfoArr" :key="index" :id="'choose'+index"> -->
            <!-- <vue-seamless-scroll :data="item" :class-option='`defaultOption${index}`'  class="box-scroll"> -->
              <!-- <vue-seamless-scroll v-if="cooperInfoArr.length>0" :data="item" :class-option="defaultOption"  class="box-scroll"> -->
              <!-- <ul class="friends-scoll anna-scoll" :id="'marquee'+index">
                  <li v-for="(items,index) in item" :key="index">
                      <img :src="items.columnUrl" alt="">
                  </li>
              </ul> -->
            <!-- </vue-seamless-scroll> -->
          <!-- </div> -->
            <div v-for="(item,index) in cooperInfoArr" :key="index">
              <div :id="'pele'+index" class="qimo8"> 
                    <div class="qimo"> 
                    <div :id="'sele'+index"> 
                    <ul> 
                      <li v-for="(items,index) in item" :key="index">
                        <img :src="items.columnUrl" alt="">
                      </li>
                    
                    </ul> 
                    </div> 
                    <div :id="'sele'+index+index"></div> 
                    </div> 
              </div>
            </div>
      </div>
    </div>
</template>
<script >
import Title  from '@/components/Title.vue'
import NavMenu  from '@/components/NavMenu.vue'
import ProductServe from '@/views/ProductServe.vue'
import vueSeamlessScroll from 'vue-seamless-scroll'
export default ({
  name: 'HomeIndex',
  components: {
    Title,
     NavMenu,
    ProductServe,
    vueSeamlessScroll
  },
  data(){
    return{
      columnList:'',
      BannerList:[],
      advInfor:'',//公司实力版图
      corAdvInfo:'',//核心优势
      solutionList:'',//解决方案
      prodServeInfo:'',//产品服务
      cooperInfo:'',//合作伙伴
      cooperInfoArr:'',
        CorCurIndex:0,
        prodCur:0,
      solutionIndex:0,
      timerr:''
     
    }
  },
  mounted(){
  },
  computed: {
    // defaultOption () {
    //     return {
    //         step: 0.6, // 数值越大速度滚动越快
    //         limitMoveNum: 0, // 开始无缝滚动的数据量 this.dataList.length
    //         hoverStop: false, // 是否开启鼠标悬停stop
    //         direction: 2, // 0向下 1向上 2向左 3向右
    //         openWatch: false, // 开启数据实时监控刷新dom
    //         singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
    //         singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
    //         waitTime: 0 // 单步运动停止的时间(默认值1000ms)
    //     }
    // }   
  },
  methods:{
    marqueeEle(PEle,SEle,Cele){
      let demo = document.getElementById(PEle);//外层可视模块 
      let demo1 = document.getElementById(SEle); //内层滚动内容模块1
      let demo2 = document.getElementById(Cele);//内层滚动内容模块2无缝对接到1后面的内容
      if(demo1){
        demo2.innerHTML=demo1.innerHTML; //把demo1的内容复制一份到demo2中
      }

      this.timerr=setInterval(function(){
        if(demo&&demo2){
          if(demo.scrollLeft-demo2.offsetWidth>=0){ 
            demo.scrollLeft-=demo1.offsetWidth;     
            }else{ 
            demo.scrollLeft++; 
            } 
        } 
      },80); 

    },
    getDataList(data){    
        this.columnList = data
        this.columnList.forEach((element,index) => {
           if(index==0){
             this.BannerList = element
           }else if(index == 1){
             this.advInfor = element
           }else if(index == 2){
             this.corAdvInfo = element
           }else if(index == 3){//解决方案
             this.solutionList = element
           }else if(index == 4){ //产品服务
             this.prodServeInfo = element
           }else if(index == 5){
             this.cooperInfo = element
              this.cooperInfoArr  = this.chunkArr(element.qmGwColumnList,10)
              this.$nextTick(()=>{
                  this.cooperInfoArr.forEach((ele,index)=>{
                      let PEle = `pele${index}`
                      let SEle = `sele${index}`
                      let SonEle = `sele${index}${index}`
                      this.marqueeEle(PEle,SEle,SonEle)
                  })
              })
                
           }
        });
    },

    corEnter(item){
        this.CorCurIndex  = item
    },
    prod(val){
       this.prodCur = val
    },
    solution(val){
       this.solutionIndex = val
    },
    // 根据指定个数分割数组
 chunkArr (arr, size) {
  //判断如果不是数组(就没有length)，或者size没有传值，size小于1，就返回空数组
  if (!arr.length || !size || size < 1) return []
  let [start, end, result] = [null, null, []]
  for (let i = 0; i < Math.ceil(arr.length / size); i++) {
    start = i * size
    end = start + size
    result.push(arr.slice(start, end))
  }
  return result
}
 
  },
   destroyed(){
       clearInterval(this.timerr)
   }
});
</script>
<style scoped lang="scss">
.silder-box{
  /deep/.el-carousel__arrow{
    display: none;
  }
  .el-carousel__container{
    position:relative;
    overflow:hidden;
     img{
      height:780px;
     }
     .banner-index{
       position:absolute;
      top:0;
      left:50%;
      margin-left:-810px;
      bottom:0;
      padding-top:230px;
      padding-left:60px;
      z-index:2;
      color:#FDFFFE;
      h4{
        color:#FDFFFE;
        font-size:64px;
        margin-bottom:20px;

        // position: relative;
        // top: 50px;
        // visibility: hidden;
        // opacity: 0;
      }
      .adv-des-txt{
        // position: relative;
        // top: 50px;
        // visibility: hidden;
        // opacity: 0;

      }
      .el-button{
        margin-top:10px;
      }
     }
     .el-carousel__item.is-active img{
        animation: swiper_img_move 8s linear 0s infinite normal both;
     }
      .el-carousel__item.is-active .banner-box{  
         .adv-des-txt{
          // top: 0px;
          // visibility: visible;
          // opacity: 1;
          animation: fadeInUp 2s;
          transition-delay: 2.5s;
          // transition: all .6s ease 1.2s;
         }
         .el-button{
          animation: fadeInUp 3s;
          transition-delay: 4.5s;
         }
         h4{
          // top:0px;
          // visibility: visible;
          // opacity: 1;
          animation: fadeInUp 1s;


          // transition: all .6s ease 1s;
         }
      }
  }
/deep/ .el-carousel__indicators--horizontal {
    left: 240px;
    bottom: 10px;
    transform: none;
  }
  /deep/ .el-carousel__button {
    width: 60px;
    height: 4px;
    background: #fff;
  }
  /deep/ .is-active .el-carousel__button {
    background-color: #0069EA;
  }
  .img{
    object-fit: cover;
  }
}
.company-adv-box{
  background:#EEF1F8;
}
.public-small-title{
    display: flex;
    align-items: center;
    justify-content: center;
    .small-title{
        font-size:40px;
        color:#1A1A1A;
        margin:0 20px;
        font-weight: bold;
    }
}
.adv-item{
  margin:0 -15px;
  li{
    width:298px;
    height:300px;
    overflow:hidden;
   
    border-radius:10px;
    margin-bottom:30px;
    margin-left:15px;
    margin-right:15px;
    .adv-box{
      display:flex;
      -webkit-box-align:center;
      -ms-flex-align:center;
      align-items:center;
      -webkit-box-pack:center;
      -ms-flex-pack:center;
      justify-content:center;
      text-align:center;
      height:300px;
      background:#fff;
      h4{
        font-size: 24px;
        font-weight: bold;
      }
      .adv-small-title{
        color:#717784;
        font-size: 16px;
      }
      .adv-img{
        display:flex;
      -webkit-box-align:center;
      -ms-flex-align:center;
      align-items:center;
      -webkit-box-pack:center;
      -ms-flex-pack:center;
      justify-content:center;
      text-align:center;
      height:100px;
      }
      img{
        height:100px;
      }
    }
    .adv-des{
      background:#0069EA;
      color:#fff;
      border-radius:10px;
      padding:40px;
      height:300px;
      h4{
        font-size:24px;
        margin:0px 0  40px 0;
        font-weight: bold;
        color:#fff;
      }
      .adv-des-txt{
        line-height: 28px;
        text-align: left;
        font-size:14px;
      }
    }
  }
  li:hover{
     .adv-box{
        margin-top:-300px;
       transition:all .6s ease
     }
     .adv-des{
       margin-top:0px;
       transition:all .6s ease 0.1s
     }
  }
}
  li:last-child:hover{
     .adv-box{
        margin-top:0px;
        transition:unset;
     }
  }
  .cor-adv-box{
    padding-bottom:100px;
    overflow:hidden;
  }
.cor-adv-list{
   .cor-adv-ul{
      li{
        width:236px;
        height:520px;
        border:1px solid rgba(27,31,60,0.25);
        margin-top:8px;
        transition: all 0.2s;
        h4{
          font-size:20px;
          padding-top:38px;
          width: 90%;
          margin: 0 auto;
          line-height: 38px;
        }
        .cor-adv-txt{
          color:#717784 ;
          line-height: 28px;
          font-size: 14px;
          text-align: left;
          padding:38px;
        }
        .cor-adv-txt.cor-adv-txt2{
          //  overflow:hidden;
          // text-overflow: ellipsis;
          // word-break: break-all;
          // display: -webkit-box;
          // -webkit-box-orient: vertical;
          // -webkit-line-clamp: 5;
          // max-height: 171px;
        }
        .cor-adv-img{
           position:relative;
            img{
            width: 90%;
              background-size: 100%;
              display: block;
              margin: 0 auto;
          }
        }
        
        .cor-open{
          display: block;
        }
        .cor-on{
          display: none;
        }
      }
      li.cur{
         width:440px;
          height:536px;
          margin-top:0;
          border: 1px solid #0069EA;
          border-radius:10px;
          position: relative;
          transition: all .2s ease;
         .cor-open{
          display: none;
         }
        //  .cor-adv-txt.cor-adv-txt2{
        //       animation: opacityChange 0.7s linear;
        //  }
        .cor-on{
           display: block;
        }
      }
   }
}
@keyframes opacityChange {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@-moz-keyframes opacityChange {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes opacityChange {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}



.product {
    width: 100%;
    background: #ffffff;
    overflow: hidden;
    padding-top: 60px;
    padding-bottom: 100px;
}

.product-center {
    width: 1620px;
    height: 600px;
    display: block;
    margin: 0 auto;
}

.product-center .title {
    width: 100%;
    font-weight: 600;
    font-size: 40px;
    color: #333333;
    text-align: center;
}

.product-center .txt {
    width: 100%;
    font-weight: 400;
    font-size: 20px;
    color: #999999;
    text-align: center;
    margin: 8px 0 26px 0;
}

.product-box {
    width: 100%;
}

.product-box .item {
    width: 236px;
    height: 520px;
    background: #ffffff;
    border: 1px solid #cccccc;
    transition: all 0.2s;
    box-sizing: border-box;
    float: left;
    margin-top: 8px;
    border-left:0;
}
.product-box .item:nth-child(1){
   border-left:1px solid #cccccc;
}

.product-box .item .off .txt1 {
    font-weight: 600;
    font-size: 20px;
    color: #1A1A1A;
    padding-top: 91px;
    text-align: center;
    width:65%;
    margin:auto;
    line-height: 36px;
}

.product-box .item .off .txt2 {
    font-weight: 400;
    font-size: 14px;
    color: #717784 ;
    // line-height: 28px;
    // padding: 10px 15px 54px;
    padding:0 15px;
    text-align: center;
     text-align: left;
     overflow:hidden; 
     text-overflow:ellipsis;
      white-space: normal;
       display:-webkit-box;
        -webkit-box-orient:vertical;
         -webkit-line-clamp:2;
         line-height: 1.5;
         margin-top:10px;
}

.product-box .item .off img {
    width: 90%;
    background-size: 100%;
    display: block;
    margin: 0 auto;
    margin-top:50px;
}

.product-box .on {
    width: 440px;
    height: 536px;
    background: #ffffff;
    border: 1px solid #0069EA;
    border-radius: 8px;
    margin-top: 0px;
}
.product-box .on.item:nth-child(1){
  border-left:1px solid #0069EA;
}

.product-box .on .txt1 {
    font-weight: 600;
    font-size: 20px;
    color: #1A1A1A;
    width: 100%;
    text-align: center;
     line-height: 36px;
    padding: 35px 15px 18px;
}

.product-box .on .txt2 {
    // width: 565px;
    font-weight: 400;
    font-size: 14px;
    color: #717784;
    animation: opacityChange 0.7s linear;
    margin: 0 auto 15px;
    line-height: 28px;
    padding:0 15px;
    text-align: left;
}

.product-box .on img {
    width: 90%;
    background-size: 100%;
    display: block;
    margin: 0 auto;
}

.product-box .on .off {
    display: none;
}

.product-box .item .off {
    display: block;
}

.product-box .item .open {
    display: none;
}

.product-box .item.on .off {
    display: none;
}

.product-box .item.on .open {
    display: block;
}
.productSolution{
  padding:80px 0 94px;
  background:url(@/assets/images/prod-bg.png) no-repeat center;
  height:880px;
    .prod-nav{
      width:380px;
      height:100%;
      background:linear-gradient(186deg,#386BF3  0,#1C367A 80%);
      position:relative;
      margin-top:-108px}
      .prod-nav-header{
        padding:20px;
        h2{
          font-size:40px;
          font-weight: bold;
          text-align: left;
          color:#fff;
        }
        }
        .prod-nav{
          padding-bottom:35px
          }
        .prod-nav li{
          padding:30px;
          transition:all .4s ease;
          text-align: left;
          position:relative;
          .solution-title-box{
              position:relative;
              .solution-icon{
                position:absolute;
                top:10px;
                left:0px;
                .icon-img-hover{
                  display: none;
                }
              }
              .solution-title{
                margin-left:60px;
                h2{
                  margin-bottom:10px;
                  color:#fff;
                }
                .soltion-small{
                  font-size:16px;
                  color:#fff;
                }
              }
          }
          }
          .prod-nav li:before{
             display: block;
             content: '';
             width:80%;
             height:1px;
             position: absolute;
             bottom:0;
             left:50%;
             margin-left: -40%;
             background: rgba(255,255,255,.15);
          }
        .prod-nav li.active,.prod-nav li:hover{
          background:#E9F1FF;
          position:relative;
          .solution-icon{      
                .icon-img-hover{
                  display: block;
                }
                .icon-img{
                  display: none;
                }
              }
          .solution-title{
                h2{
                  margin-bottom:10px;
                  color:#1a1a1a;
                }
                .soltion-small{
                  font-size:16px;
                  color:#188FFE;
                }
              }
          }
          .prod-nav li.active:after,.prod-nav li:hover:after{
             display: block;
             content: '';
             width:4px;
             height:100%;
             position: absolute;
             top:0;
             left:0;
             background: #188FFE;
          }  
  }

.productSolution .contain{
  display:flex;justify-content:space-between;
  .prod-list{
    // padding-right:12px;
    // width:1200px;
    // background:#fff;
    // border-radius:10px;
    .prod-item{
       padding-right:12px;
    width:1200px;
    background:#fff;
    border-radius:10px;
      display:flex;
      flex-wrap:wrap;
      align-content:space-between;
      justify-content:space-between;
      padding:30px 15px;
      .soltion-list-div{
          max-height: 680px;
      padding:0 30px;
      overflow-y: auto;
      }
      .soltion-txt{
         margin:30px 0;
         li{
          position:relative;
          width:50%;
          padding-left:90px;
          padding-right:20px;
          margin:30px 0;
          .table-txt{
            color:#717784;
            line-height: 28px;
            
          }
        }
        .span-title{
          position:absolute;
          top:0;
          left:0;
          width:70px;
          height:70px;
          background: #E9F1FF;
          font-weight: bold;
          font-size:20px;
          line-height: 24px;
          padding:10px;
        }
      }
      }
    
    }
  }

// 产品服务

.product-serve-wrapper {
    padding: 0px 0 30px;
    background: url(@/assets/images/product-serve.png) no-repeat center;
}
// 合作伙伴
.choose {
    width: 100%;
    overflow: hidden;
    margin-bottom:30px;
}

.choose .title {
    width: 100%;
    font-weight: 600;
    font-size: 40px;
    color: #333333;
    text-align: center;
    margin-bottom: 64px;
}

.choose .box {
    width: 100%;
}

.aniitem {
    width: 100%;
    height: 83px;
    margin-bottom: 24px;
    animation: move 1500s  infinite linear;
    white-space: nowrap;
}

.aniitem li {
    display: inline-block;
    width: 168px;
    height: 72px;
    margin-right: 54px;
    img{
      box-shadow:  0 0 4px #717784;
      display: block;
      margin:0 auto;
    }
}

.aniitem2 li:nth-child(1) {
    margin-left: -330px;
}

.aniitem3 li:nth-child(2) {
    margin-left: -230px;
}
.choose-wrapper{
  overflow:hidden;
  height:100px;
  .friends-scoll{
  //  width:1200px;
  display: flex;
      &:last-child {
            margin-right: 0px;
          }
          li {
            margin-right: 20px;
            margin-bottom: 20px;
            padding: 10px;
            img {
              display: flex;
               box-shadow: 0px 0px  8px #ECECEC;

            }
          }
  }
}

@keyframes move {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(-3613px, 0, 0);
        // transform: translate3d(-1110px, 0, 0);
    }
}

@-moz-keyframes move {
    0% {
        transform: translate3d(0, 0, 0);
    }

    100% {
        transform: translate3d(-3613px, 0, 0);
    }
}

@-webkit-keyframes move {
    0% {
        transform: translate3d(0, 0, 0);
    }

    100% {
        transform: translate3d(-3613px, 0, 0);
    }
}
.qimo8 {
  overflow:hidden; 
  width:100%;
  .qimo{
  width:8000%; 
  height:90px;
  div{ 
    float:left;
    ul {
        float:left;
        height:90px; 
        overflow:hidden; 
        zoom:1;
          li{
          float:left;
          height:90px;
          padding:10px;
          margin-bottom:15px;
          img{
            box-shadow: 0 0 8px #e8e8e8;
          }
        }
      } 
    }
}
}
@-webkit-keyframes swiper_img_move{

    0%{

        -webkit-transform:scale(1,1);

        transform:scale(1,1);

    }

    50%{

        -webkit-transform:scale(1.1,1.1);

        transform:scale(1.1,1.1);

    }

    100%{

        -webkit-transform:scale(1,1);

        transform:scale(1,1);

    }

}

@keyframes swiper_img_move{

    0%{

        -webkit-transform:scale(1,1);

        transform:scale(1,1);

    }

    50%{

        -webkit-transform:scale(1.1,1.1);

        transform:scale(1.1,1.1);

    }

    100%{

        -webkit-transform:scale(1,1);

        transform:scale(1,1);

    }

}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

</style>