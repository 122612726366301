<template>
    <div class="footer-box">
        <div class="public-page">
            <div class="footer-top-nav flex">
                <div class="footer-left flex">
                    <div class="footer-item">
                        <h4>不动产数字解决方案</h4>
                        <p class="small-tips"></p>
                        <ul class="footer-nav">
                            <li v-for="(item,index) in companyInfo.immovablesList" @click="NavButton(item.excessiveUrl)" :key="index">{{item.excessiveName}}</li>
                        </ul>
                    </div>
                     <div class="footer-item">
                        <h4>产品与功能</h4>
                        <p class="small-tips"></p>
                        <ul class="footer-nav">
                            <li v-for="(item,index) in companyInfo.productList" :key="index" @click="NavButton(item.excessiveUrl)">{{item.excessiveName}}</li>
                        </ul>
                    </div>
                     <div class="footer-item">
                        <h4>客户服务与支持</h4>
                        <p class="small-tips">为您提供全方位的综合性服务，咨询服务一键直达</p>
                        <ul class="footer-nav">
                            <li v-for="(item,index) in companyInfo.customerServiceList" @click="NavButton(item.excessiveUrl)" :key="index">{{item.excessiveName}}</li> 
                        </ul>
                    </div>
                </div>
                <div class="footer-right flex">
                    <div class="footer-item">
                        <h4>预约演示</h4>
                        <p class="small-tips">想要了解更多产品详情</p>
                        <div class="footer-ewm-box">
                            <div class="ewm"><img :src="companyInfo.demoQrCode" alt=""></div>
                            <p class="try-p">申请试用</p>
                        </div>
                    </div>
                    <div class="footer-item">
                        <h4>加入我们</h4>
                        <p class="small-tips">与齐谋一起开创数字化未来，we want you!</p>
                        <div class="footer-ewm-box">
                            <div class="ewm flex join-ewm">
                                <img :src="item" alt="" v-for="(item,index) in companyInfo.joinUsQrCode" :key="index">
                            </div>   
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-middle">
                <span class="telphone">电话：{{companyInfo.companyPhone}}</span>
                <span class="company-address">地址：{{companyInfo.companyAddress}}</span>
            </div>
            <!-- 添加备案链接 -->
            <div class="company-copy text-center">
                {{companyInfo.companName}}<span v-html="companyInfo.companyCopyright"></span>
            </div>
        </div>
        <LeftFloat :companyInfo = 'companyInfo'></LeftFloat>
    </div>
</template>
<script>
import { getQmGwCompany } from '@/api/api'
import LeftFloat from '@/components/LeftFloat.vue'
export default ({
   name:'Footer',
   components:{
    LeftFloat
   },
   data(){
        return{
            companyInfo:''
        }
   },
   created(){
        this.initData() 
   },
   mounted(){
let route = this.$router.options.routes;
            console.log("底部路由~~~~~~",route);
   },
   methods:{
        initData(){
            getQmGwCompany().then(res=>{
                if(res.code == 0){
                    this.companyInfo = res.data
                    this.companyInfo.joinUsQrCode = this.companyInfo.joinUsQrCode?this.companyInfo.joinUsQrCode.split(','):''   
                }
                
            })
        },
        NavButton(url){
            let route = this.$router.options.routes;
            route.forEach(ele => {
              if(ele.path == url){
                this.$router.push(url)
                return 
              }else{
                 window.open=url
              }
            });
        },
   }
})
</script>


<style scoped lang="scss">
    .footer-box{
          background:url(@/assets/images/footer-bg.png) no-repeat center/cover;
          color:#fff;
          .footer-top-nav{
            padding:40px 0;
            img{
                width:100px;
                height:100px;
            }
            .footer-left{
                width:970px;
                position:relative
            }
            .footer-right{
                .join-ewm{
                    img{
                        margin-right:20px;
                    }
                }
                .try-p{
                 margin-left:20px;
                 margin-top:10px;
                 color: rgba(255, 255, 255, 0.5);
               }
          }
          
           .footer-item{
                    margin-right:50px;
                    position:relative;
                    h4{
                        margin-bottom:10px;
                        font-size: 18px;
                        color:rgba(255,255,255,.8)
                    }
                    .small-tips{
                        height:40px;
                        color:rgba(255,255,255,.5)
                    }
                    .footer-nav {
                        li{
                           padding-bottom:10px;
                           color:rgba(255,255,255,.6);
                           font-size: 12px;
                           cursor: pointer;
                        }
                        li:hover{
                          color:#fff;
                        }
                    }
                    img{
                        width:100px;
                        height:100px;
                    }
                }
          }
          .footer-left{
                .footer-item:last-of-type:after{
                    display: block;
                    content: "";
                    position: absolute;
                    height:50%;
                    width:1px;
                    background:rgba(255,255,255,.3);
                    bottom:25px;
                    right:-94px;
                    z-index: 1;
                    }
                .footer-item:last-of-type:before{
                display: block;
                content: "";
                position: absolute;
                height:15%;
                width:4px;
                background:#A1A1A1;
                top:87px;
                right:-95px;
                z-index: 2;
                }
            }
         .footer-middle{
            border-bottom:1px solid #51575D;
            padding:30px 0;
            color:rgba(255,255,255,.5);
            .telphone{
                margin-right:50px;
            }
          }
          .company-copy{
            padding:30px 0;
            color:rgba(255,255,255,.5)
          }
    }
</style>
<style lang="scss">
 .company-copy{
     a{
        color:rgba(255,255,255,.5)
     }
 }
</style>
