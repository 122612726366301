import request from "@/utils/request";
export function login (data) {
    return request({
      url: '/qimou-evs-ucenter/evs/doLogin.json',
      method: 'post',
      data
    })
  }
/**
 * 新增留言板信息
 * @param data
 * @returns {AxiosPromise}
 */
export function addQmGwBookingInfo(data){
  return request({
    url: '/qimou-system/qimou/system/qmGw/addQmGwBookingInfo.json',
    method: 'post',
    data
  })
}

/**
 * 新增预约信息
 * @param data
 * @returns {AxiosPromise}
 */
 export function addQmGwGuestbook(data){
  return request({
    url: '/qimou-system/qimou/system/qmGw/addQmGwGuestbook.json',
    method: 'post',
    data
  })
}
/**
 * 获取公司信息
 * @param data
 * @returns {AxiosPromise}
 */
 export function getQmGwCompany(data){
  return request({
    url: '/qimou-system/qimou/system/qmGw/getQmGwCompany.json',
    method: 'get',
    data
  })
}

/**
 * 获取所有菜单
 * @param data
 * @returns {AxiosPromise}
 */
 export function getQmGwMenuDtoList(data){
  return request({
    url: '/qimou-system/qimou/system/qmGw/getQmGwMenuDtoList.json',
    method: 'get',
    data
  })
}

/**
 * 获取官网栏目详情信息
 * @param data
 * @returns {AxiosPromise}
 */
 export function queryQmGwMenuDtoList(data,id){
  return request({
    url: `/qimou-system/qimou/system/qmGw/queryQmGwMenuDtoList.json?menuId=${id}`,
    method: 'get',
    data
  })
}