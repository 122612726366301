<template>
    <div class="nav">
        <div class="header-top-box" :class="{'cur':scrollStatu}">
            <div class="public-page">
                <div class="top-wrapper clearfix">
                    <div class="top-right-wrapper fl">
                        <div class="header-left fl">
                            <div class="logo-img logo2" @click="logoBtn">
                                <img src="../assets/images/logo-2.png"/>
                            </div>
                            <div class="logo-img logo1" @click="logoBtn">
                                <img src="../assets/images/logo.png"/>
                            </div>
                        </div>
                        <div class="header-right fl">
                            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                                <div v-for="item in menuList" :key="item.menuId" class="fl"> 
                                    <el-menu-item v-if="item.children.length==0" :index="item.url"><router-link :to="item.url">{{item.menuName}}</router-link></el-menu-item>
                                    <el-submenu  v-else :index="item.url">
                                        <template slot="title" >{{item.menuName}}</template>
                                        <el-menu-item v-for="son in item.children" :key="son.menuId" :index="item.url" :class="{'sonActive':sonCur===son.url}"><router-link :to="son.url">{{son.menuName}}</router-link></el-menu-item>
                                     </el-submenu>
                                        
                                </div>
                            </el-menu>
                        </div>
                    </div>
                    <div class="header-button fl" @click="OrderExperience">
                        <el-button type="primary">预约体验</el-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="order-box animate__fadeIn animate__animated" v-if="orderStatus">
            <div class="close-oreder"><span class="iconfont icon-icon-guanbi" @click="closeOrder"></span></div>
            <h2 class="text-center order-title h4Color font24">预约体验</h2>
            <div class="order-form">
                <el-form :model="ruleForm"  ref="ruleForm" :rules="rules" class="demo-ruleForm">
                    <el-form-item label="" prop="bookingName">
                        <el-input v-model="ruleForm.bookingName" placeholder="请输入您的姓名">
                            <i slot="prefix" class="iconfont icon-icon-yonghu"></i>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="" prop="bookingPhonenumber">
                        <el-input v-model="ruleForm.bookingPhonenumber" placeholder="请输入您的手机号码">
                            <i slot="prefix" class="iconfont icon-icon-shouji"></i>
                        </el-input>
                    </el-form-item>
                        <el-form-item label="" prop="bookingCompany">
                        <el-input v-model="ruleForm.bookingCompany" placeholder="请输入您的公司">
                            <i slot="prefix" class="iconfont icon-icon-gongsi"></i>
                        </el-input>
                    </el-form-item>
                        <el-form-item label="" prop="bookingCompanyAddress">
                        <el-input v-model="ruleForm.bookingCompanyAddress" placeholder="公司所在地">
                            <i slot="prefix" class="iconfont icon-icon-dizhi"></i>
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm(ruleForm)">提交</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="form-tips text-center">
                <p>提交信息后，您将获得：专属客户经理为您贴心服务</p>
                <p>您的信息仅用于申请及回访，齐谋信息将严格保密</p>
            </div>
        </div>
    </div>
</template>
<script>
import {addQmGwGuestbook,getQmGwMenuDtoList,queryQmGwMenuDtoList} from '@/api/api'
export default ({
  name: 'NavMenu',
  data (){
    const validateMobile = (rule, value, callback) => {
        const reg = /^1[3-9]\d{9}$/
        if (value === '') {
            callback(new Error('请输入手机号码'))
        } 
        else if (!reg.test(value)) {
        callback(new Error('请输入正确的11位手机号'))
        } 
        else {
            callback()
        }
    }
    return{
        scrollStatu:false,
        ruleForm: {
            bookingName: '',
            bookingPhonenumber: '',
            bookingCompany: '',
            bookingCompanyAddress: '',
            },
            rules: {
                bookingName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
                bookingPhonenumber:[{required:true,validator: validateMobile,trigger:'blur'}]
            },
        orderStatus:false,
        menuList:[],
        loading:'',
        timer:null

    }
  },
  created(){
    // if(document.documentElement.scrollTop || document.body.scrollTop>=50){
    //     this.returnTop()
    // }
    this.initData()
  },
  computed:{
    activeIndex(){
         if(this.$route.meta.activeMenu) {
            return this.$route.meta.activeMenu
         }
         return this.$route.path
       },
       sonCur(){
         return this.$route.path
       }
  },
  mounted(){
    window.addEventListener('scroll', this.windowScroll)
    let element = document.querySelector('.el-menu--popup')
    if(element){
        element.style.marginTop = 0 +'px'
    }
    //监听鼠标滚动事件
    // window.addEventListener('mousewheel',this.clearTimer)||window.addEventListener('DOMMouseScroll',this.clearTimer)
  },
 
  methods:{
    windowScroll(){
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        this.scrollStatu =  scrollTop>50?true:false
    },
    returnTop(){
         this.timer = setInterval(function(){
            let osTop = document.documentElement.scrollTop || document.body.scrollTop;
            let speed = Math.floor(-osTop / 6);
            document.documentElement.scrollTop = document.body.scrollTop = osTop + speed;
            if(osTop == 0){
                clearInterval(this.timer);
            }
        },80);
    },
    clearTimer(){
         clearInterval(this.timer);
    },
    initData(){
        this.loading = this.$loading({
            lock: false,
            text: '数据加载中......',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.5)'
        })
        getQmGwMenuDtoList().then(res=>{
             if(res.code == 0){
                this.menuList = res.data
                this.loading.close();
                 this.getColumn()
             }
        }).catch(err=>{
             this.loading.close();
        })
    },
    getColumn(){
         let id = ''
         this.menuList.forEach(element => {   
            if(element.children.length>0){
                element.children.forEach(item=>{
                    if(this.$route.path === item.url){
                        id = item.menuId
                    }
                })
            }else{
                if(this.$route.path === element.url){
                        id = element.menuId
                    }
            }
        });
        let obj = {
                    menuId:id?id:''
                }
        queryQmGwMenuDtoList({},obj.menuId).then(res=>{
            this.$emit("getDataList",res.data)
        })
    },
    liEnter(item){
        if(item.children&&item.children.length>0){
             item.statu = true
        }
    },
    NavClick(item){
        if(!item.children){
            this.$router.push(item.path)
        }
    },
    liLeave(item){
        if(item.children&&item.children.length>0){
             item.statu = false
        }
    },
    childNav(item){
        this.$route.push(item.path)
    },
    OrderExperience(){
        this.orderStatus = true
    },
    closeOrder(){
        this.orderStatus = false
    },
    handleSelect(key, keyPath) {
        console.log(key, keyPath);
    },
    logoBtn(){
        this.$router.push('/')
    },
      // 提交
    submitForm(ruleForm){
        this.$refs['ruleForm'].validate((valid) => {
            if (valid) {
                addQmGwGuestbook({...this.ruleForm}).then(res=>{
                    this.$message({
                    message: '提交成功',
                    type: 'success'
                    });
                    this.resetForm()
                    this.orderStatus = false
                })
            } else {
                this.$message({
                message: '请填写必填项后再提交',
                type: 'error'
                });
            }
            });
    },
    resetForm(){
        this.ruleForm.bookingName = '';
        this.ruleForm.bookingPhonenumber = '';
        this.ruleForm.bookingCompany = '';
        this.ruleForm.bookingCompanyAddress= ''
    }
     
    },
    beforeDestroy(){
        clearInterval(this.timer);
    }
});
</script>
<style lang="scss" scoped>
.order-box{
    width:520px;
    height:590px;
    background:#fff;
    position:fixed;
    top:50%;
    margin-top:-295px;
    left:50%;
    margin-left:-260px;
    border-radius: 10px;
    z-index: 111;
    box-shadow: 0 0 24px rgba(0,0,0,0.2);
    .close-oreder{
        line-height: 50px;
        text-align: right;
        padding:0 15px;
        color:#c9c9c9;
        span.iconfont{
            cursor: pointer;
        }
    }
    .order-form{
        padding:30px;
        .el-button{
            width:100%;
            font-size: 18px;
            background: #0069EA linear-gradient(to right, #0069EA, #188FFE);
        }
    }
    .form-tips{
        p{
            margin-bottom:5px;
            color:rgba(0,0,0,.3);
    }
    }
}
.header-top-box{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height: 80px;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    border-bottom: 1px solid rgba(255,255,255,.1);
    transition: all .5s ease;
    .el-menu{
        background:transparent;
        a{
            color:inherit;
        }
    }
    .el-menu.el-menu--horizontal{
        border-bottom:none;
        font-size:16px;
        color:#fff;
    }
    .el-menu--horizontal{
      /deep/  .el-menu-item{
            height:80px;
            line-height: 80px;
            font-size: 16px;
            color:#fff;
            padding:0 40px;
            border-bottom: none;
            position:relative; 
        }
        .el-menu-item:after{
            display: block;
            content: "";
            width: 0%;
            right: 0;
            left: 0;
            margin: 0 auto;
            opacity: 1;
            height: 4px;
            position: absolute;
            bottom: 0;
            display: block;
            background: #0069EA;
            transition: width .3s ease,opacity .2s ease .2s;
        }
        .el-menu-item:not(.is-disabled):hover{
            background-color:transparent;
            color:#0069EA;
            &:after{
                width: 20%;
            }
        } 
       /deep/ .el-submenu {
            .el-submenu__title{
                 height:80px;
                line-height: 80px;
                font-size:16px;
                 color:#fff;
                 padding:0 40px;
                 position: relative;
                 transition: all 0.8s ease;
                 i{
                    display: none;
                 }
            }
       
        .el-submenu__title:after{
            display: block;
            content: "";
            width: 0%;
            right: 0;
            left: 0;
            margin: 0 auto;
            opacity: 1;
            height: 4px;
            position: absolute;
            bottom: 0;
            display: block;
            background: #0069EA;
            transition: width .5s ease,opacity .5s ease .5s;
        }
    }
/deep/ .el-submenu.is-active {
            .el-submenu__title{
                color:#0069EA;
                border-bottom:none;
            }
}
      /deep/ .el-menu-item:not(.is-disabled):focus,.el-menu-item:not(.is-disabled):hover {
            outline: 0;
            color: #0069EA;
        }
        
    }
    .el-menu {
        .el-menu-item.is-active{
        color:#0069EA
        }
    }
   .el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
        border-bottom:none!important;
        color:#0069EA!important;
   }
    
    .el-menu--horizontal{
       /deep/ .el-menu-item.is-active{
            color:#0069EA;
            border-bottom:none;
        }
        /deep/.el-submenu:hover {
         .el-submenu__title {
                background-color:transparent;
                
                  animation-name: colors;
                animation-duration: .8s;
                color:#0069EA;
            }
             .el-submenu__title:hover{
                background-color:transparent;
                  animation-name: colors;
                animation-duration: .8s;
                color:#0069EA;
                &:after{
                    width:20%;
                }
             }
        }
        .el-submenu.is-active {
            .el-submenu__title{
                border-bottom:none;
            }
        }
        .el-menu-item:not(.is-disabled):focus{
            background-color:transparent;
        }

       
       
    }
    
    .header-left{
        height:80px;
        overflow: hidden;
    }
   .logo-img{
         height:80px;
         display:flex;
        -webkit-box-align:center;
        -ms-flex-align:center;
        align-items:center;
        -webkit-box-pack:center;
        -ms-flex-pack:center;
        justify-content:center;
        text-align:center;
        // transition: all 1s ease-in;
        img{
            width:180px;
            height:26px;
        }
      }
      .logo2{
        margin-top:-80px;
      }
      .top-right-wrapper{
        width:calc(1620px - 110px);
      }
      .header-button{
        width:110px;
        display: flex;
        -webkit-box-align:center;
        -ms-flex-align:center;
        align-items:center;
        -webkit-box-pack:center;
        -ms-flex-pack:center;
        justify-content:center;
        height:80px;
        .el-button{
            background: #0069EA linear-gradient(to right, #0069EA, #188FFE);
            border:none;
        }
        }
  
   }
   
.el-menu--horizontal{
    /deep/.el-menu-item:not(.is-disabled):focus{
        color:#0069EA;
    }
    /deep/.el-menu-item:not(.is-disabled):hover{
         color:#0069EA;
    }
} 
 .header-right{
    margin-left:150px;
    .nav-wrapper{
          display: flex;
        li{
            line-height: 80px;
            font-size:16px;
            position:relative;
            min-width:120px;
            margin-right:15px;
            font-weight: 500;
            color:#fff;
            cursor: pointer;
            .children-box{
                position:absolute;
                top:80px;
                left:0;
                width:100%;
                opacity: 0;
                // display: none;
                background:#fff;
                min-height: 0px;
                max-height: 0px;
                height:0px;
                overflow:hidden;
                transition: all .3s ease;
                .children-list{
                    color:#333;
                    font-size:14px;
                    line-height: 42px;
                }
                .children-list:hover{
                     color:#0069EA;
                }
            } 
           
        }
        li.is-active{
            color:#0069EA;
        }
        li.is-active:before{
            display: block;
            content: "";
            width: 100%;
            right: 0;
            left: 0;
            margin: 0 auto;
            opacity: 1;
            height: 0px;
            position: absolute;
            bottom: 0;
            display: block;
            background: #0069EA;
            transition: width .3s ease,opacity .2s ease .2s;
        }
        li:before{
             display: block;
            content: "";
            width: 0%;
            right: 0;
            left: 0;
            margin: 0 auto;
            opacity: 0;
            height: 4px;
            position: absolute;
            bottom: 0;
            background: #fff;
            transition: width .3s ease,opacity .2s ease .2s;
        }
        li:hover span{
            color:#006aff;
             animation-name: colors;
            animation-duration: .5s;
        }
        li:hover {
            .children-box{
                max-height:420px;
                min-height: 42px;
                opacity: 1;
                height:auto;     
                transition: all .8s ease
            }
        }
        
        li.leave{
            .children-box{
                max-height:0px;
                min-height: 0px;
                opacity: 0;
                display: none;
                transition: all .5s ease
                }
        }
        li:hover:before{
            width: 20%;
            background: #0069EA;
            opacity: 1;
            transition: width .3s ease .1s,opacity .2s ease;
        }
    }
}
.header-top-box:hover{
    background:rgba(255,255,255,.95);
    box-shadow: 0 0 6px #000;
    .nav-wrapper{
        li{
            color:#333;
        }
          li.is-active{
             color:#0069EA 
          }
          li.is-active:before{
             background: #0069EA;
             transition: width .3s ease .1s,opacity .2s ease;
          }
    }
    .logo1{
        margin-top:-160px;
    }
    .logo2{
        margin-top:0px;
      }
        .el-menu--horizontal{
      /deep/  .el-menu-item{
            color:#333;
        }
       /deep/ .el-submenu {
            .el-submenu__title{      
                color:#333;  
               
            }
        }
       /deep/ .el-menu-item.is-active{
            color:#0069EA;
        }
        /deep/ .el-submenu.is-active {
            .el-submenu__title{
                color:#0069EA;
                border-bottom:none;
            }
}
      /deep/ .el-menu-item:not(.is-disabled):focus,.el-menu-item:not(.is-disabled):hover {
            outline: 0;
            color: #0069EA;
        }
    
    }
}
.header-top-box.cur{
     background:rgba(255,255,255,.95);
     box-shadow: 0 0 6px #000;
    .nav-wrapper{
        li{
            color:#333;
        }
          li.is-active{
             color:#0069EA;
          }
          li.is-active:before{
             background: #0069EA;
             transition: width .3s ease .1s,opacity .2s ease;
          }
    }
    .logo1{
        margin-top:-160px;
    }
    .logo2{
        margin-top:0px;
      }
       .el-menu--horizontal{
      /deep/  .el-menu-item{
            color:#333;
        }
       /deep/ .el-submenu {
            .el-submenu__title{
                color:#333;   
            }
        }
       /deep/ .el-menu-item.is-active{
            color:#0069EA;
        }
        /deep/ .el-submenu.is-active {
            .el-submenu__title{
                color:#0069EA;
                border-bottom:none;
            }
        }
         
      /deep/ .el-menu-item:not(.is-disabled):focus,.el-menu-item:not(.is-disabled):hover {
            outline: 0;
            color: #0069EA;
        }
    
    }
}
// @keyframes colors {
//   0% {
//     color: #fff;
//   }
//   100% {
//     color: #0069EA;
//   }
// }

.el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover{
    color:#0069EA;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled){
    a{
         color:inherit;
         display: inline-block;
         width:100%;
         height:100%;
    }
}

.el-menu{
        .el-menu-item.sonActive{
            color:#0069EA!important;
        }
    }

</style>
<style>
    .el-menu--popup-bottom-start{
        margin-top:0px!important;
    }
    .el-menu--popup{
        border-radius: 0!important;
        
    }
    .el-menu-item{
        text-align: center!important;
    }
</style>


