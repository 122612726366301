<template>
    <div class="productServe-box">
        <div class="data-tabs">
            <div
              v-for="(item, index) in prodServeInfo.qmGwColumnList"
              :key="index"
              class="mc-tabs-item a1a1a1"
              :class="{'mc-tabs-active': tabsIndex===index}"
              @click="tabsIndex = index">
              {{ item.columnTitle }}
            </div>
        </div>
         <div class="product-serve-paragraph">
            <div v-for="(item, index) in prodServeInfo.qmGwColumnList" :key="index" style="background:#fff;">
            <div class="serve-item" :style="{background:'url('+item.columnUrl+') no-repeat right'}" :class="{'animate__animated':tabsIndex==index, 'animate__fadeIn':tabsIndex==index}" v-if="tabsIndex==index">
                <div class="serve-content">
                    <h4 class="h4Color font24">{{item.columnTitle}}</h4>
                    <p class="serve-text" v-html="item.columnDescribe"></p>
                </div>
            </div>
            </div>
           
         </div>
    </div>
  
</template>
<script>
export default ({
  name: 'ProductServe',
  props:{
    prodServeInfo:{
        type:[Object,String],
        default:''
    }
  },
  data (){
    return{
        tabsIndex: 0,
        tabs: [
          { name: '不动产数据大屏' },
          { name: '存量房可视化系统' },
          {
            name: '不动产询价系统'
          },{ name: '不动产数据大屏' },
          { name: '存量房可视化系统' },
          {
            name: '不动产询价系统'
          }
        ],
    }
    }
})
</script>
<style scoped lang="scss">
.data-tabs{
    display: flex;
     align-items: center;
    justify-content: center;
    padding:0px 0 15px 0;
    border-bottom: 1px solid rgba(0,0,0,.15);
    .mc-tabs-item{
        font-size:20px;
        padding:0 15px;
        cursor: pointer;
    }
    .mc-tabs-active{
        color:#0069EA;
         position:relative;
    }
    .mc-tabs-active:after{
        position:absolute;
        display: block;
        content: '';
        width:60%;
        bottom:-16px;
        left:50%;
        margin-left: -30%;
        height:2px;
        background:#0069EA;
    }
}
.serve-item{
     background:url(@/assets/images/serve1.png) no-repeat center;
     min-height:560px;
     margin-top:30px;
     .serve-content{
        padding-left: 200px;
        padding-top: 50px;
        width: 500px;
        .serve-text{
            color:#717784;
            line-height: 28px;
        }
     }
}
</style>
