<template>
    <div class="public-page">
        <div class="public-small-title flex padding-top">
            <div class="icon-left">
                <img src="@/assets/images/icon-left.png" v-if="type&&!special&&no"/>
                <img src="@/assets/images/icon-left1.png" v-if="!type&&no"/>
                <img src="@/assets/images/icon-title-left-2.png" v-if="special"/>
            </div>
            <div class="small-title" :class="{'cur':!type&&!special,'active':special,'noCur':!no}">
                {{title}}
                <div class="icon-bg" v-if="special"><img src="../assets/images/icon-title-bg.png" alt=""></div>
            </div>
            <div class="icon-right">
                <img src="@/assets/images/icon-right.png" v-if="type&&!special&&no"/>
                <img src="@/assets/images/icon-right1.png" v-if="!type"/>
                <img src="@/assets/images/icon-title-right-2.png" v-if="special"/>
            </div>
        </div>
    </div>
</template>
<script>
export default ({
    name:'Title',
    props:{
        title:{
            type:String,
            default:'标题'
        },
        type:{
            type:Boolean,
            defalut:true
        },
        special:{
            type:Boolean,
            defalut:false
        },
        no:{
            type:Boolean,
            default:true
        }
    }

})
</script>
<style scoped lang="scss">
.public-small-title{
    display: flex;
    align-items: center;
    justify-content: center;
    .small-title{
        font-size:40px;
        color:#1A1A1A;
        margin:0 20px;
        font-weight: bold;
    }
     .small-title.cur{
        color:#fff;
     }
     .small-title.active{
        color:#0069EA;
        position:relative;
        .icon-bg{
            position:absolute;
            width:100px;
            height:100px;
            right:0;
            top:-40px;
        }
     }
     .small-title.noCur{
        color:#0069EA;
     }
}
</style>
